.p-checkbox-label {
    font: normal normal normal 14px/21px Poppins;
    letter-spacing: 0px;
    color: #313131;
}



.create-user {

    padding: 20px;
    margin: 4%;
    background-color: #ffffff;


    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;


    .reg-title {
        font: normal normal normal 22px/33px Poppins;
        font-weight: 600;
        letter-spacing: 0.14px;
        color: var(--primary-color);
        margin: 10px;
    }

    .anot-title {
        font: normal normal normal 16px Poppins;
        font-weight: 600;
        letter-spacing: 0.14px;
        color: var(--primary-color);
        margin: 10px 10px 10px 0px;
    }

    .create-form {}

    .add-grade-btn {
        margin-top: 28px;
    }

    .delete-grade-btn {
        margin-top: 28px;
    }

    .no-grades {
        font: normal normal normal 12px/18px Poppins;
        letter-spacing: 0px;
        color: #737373;
    }
}



.user-info-d {
    width: 30%;

    .user-info {}



}


.password-copy {

    font-size: 18px;
    font-weight: 500;
}

.uploaded-photo {
    width: 160px;
    height: 160px;
}

.w-100 {
    width: 100%;
}

.h-100 {
    height: 50%;
}

.org-img-head {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.id-card {
    width: 320px;
    height: 100px;
    background-color: #ffffff;
    border-radius: 15px;
    margin-top: 10px // border-radius: 1px solid #f9e07c;
}

.id-container {
    display: flex;
    flex-direction: column;
    background-image: url('../../images/Id_card_background.png');
    background-repeat: no-repeat;
    border-radius: 10px;
    padding: 0px;
    white-space: nowrap;
    background-size: contain;
    width: 350px;
    height: 560px;

    h1 {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    details {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .det-head {
        color: #6D5310;
    }

    .det-value {
        font-weight: bold;
        color: #176A8C;
        white-space: break-spaces;
    }

    .prince-sign {
        display: flex;
        justify-content: flex-end;
        margin: 0px 20px 0px 0px;
    }
}

.desc-div {
    margin-top: -7px;
}

.student-photo {
    display: flex;
    flex-direction: row;
    justify-content: center;

    img {
        margin: 50px 0px 0px 10px;
        border-radius: 100%;
    }
}

.text-end {
    text-align: end;
}

.bg-logo-container {
    display: flex;
    justify-content: space-around;
}

.bg-logo {
    height: 160px;
    width: 200px;
    margin: 0px 0px -130px 0px;
    opacity: .40;
    border-radius: 100%;
}

.course-custom {
    //font-family: 'Alex Brush', cursive;
    font: normal normal 400 12px/12px AlexBrush;
    
    text-align: center;
   
}

.h1-custom {
    font-family: 'Alex Brush', cursive;
    //font: normal normal 800 12px/17px AbhayaLibre;
    //text-align: center;
    font-size: medium;
   
}

