.ma-table-wrapper {
    .ma-table-d {
        //overflow-x: scroll;

        .ma-table-tbl {
            // overflow-x: scroll;
        }
    }


    .col-header {
        width: 200px;
    }
}

.q-wise-report {
    .ma-table-d {
        //overflow-x: scroll;

        .ma-table-tbl {
            // overflow-x: scroll;
        }
    }


    .col-header {
        width: 200px;
    }

    .col-answer {
        width: 120px;
    }
}

.q-s-wise-report {
    .ma-table-d {
        //overflow-x: scroll;

        .ma-table-tbl {
            // overflow-x: scroll;
        }
    }


    .col-header {
        width: 200px;
    }

    .col-answer {
        min-width: 210px;
    }
}