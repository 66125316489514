.admin-management {
    //  background-color: var(--body-background);
    padding: 20px;

    .doubts-tabs {
        text-align: center;

        .doubts-tab-w {
            padding: 0px 10px;
        }

        p {
            font-size: 16px;
            letter-spacing: 0.09px;
            color: var(--shadow-60);
            font-weight: 600;
            margin-bottom: 10px;

            padding: 7px;
        }
    }
}

.tab-active {
    background: var(--theme-100) 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px var(--shadow-85);
    border-radius: 10px 10px 0px 0px;


    //border-bottom: 3px solid var(--theme-45);
}

.tab-p {
    font: normal normal normal 15px/23px Poppins;
    letter-spacing: 0.09px;
    color: #BCBCBC;

    display: flex;
    justify-content: center;
}

.tab-active-p {
    //  background: var(--theme-100) 0% 0% no-repeat padding-box;
    //box-shadow: 0px 0px 6px var(--shadow-85);
    // border-radius: 10px 10px 0px 0px;
    color: var(--shadow-10) !important;
    border-bottom: 4px solid var(--theme-45);

}



.tab-active-body {
    //box-shadow: 0px 0px 6px var(--shadow-85);
    box-shadow: 0 3px 6px var(--shadow-85);
    background: var(--theme-100) 0% 0% no-repeat padding-box;
    //box-shadow: 0px 3px 6px var(--shadow-85);
    border-radius: 0px 10px 10px 10px;
}

.tab-active-n2 {
    border-radius: 10px 10px 10px 10px;
}

.ma-box-style1 {
    // background: var(--body-background) 0% 0% no-repeat padding-box;
    padding: 16px 24px;
    box-shadow: 0px 2px 3px var(--shadow-85);
    border-radius: 10px;
    margin: 16px;
    background-color: var(--body-background);
}

.ma-box-style2 {
    // background: var(--body-background) 0% 0% no-repeat padding-box;
    background: var(--theme-100) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px var(--shadow-85);
    border: 0.5px solid var(--theme-85);
    border-radius: 5px;
    padding: 16px 24px;
    border-radius: 10px;
    margin: 16px;
}


.permission-tab {

    padding-left: 10px;

    .group {
        font: normal normal normal 14px/21px Poppins;
        letter-spacing: 0px;
        color: #545454;
    }

    .p-name {
        font: normal normal normal 13px/20px Poppins;
        letter-spacing: 0px;
        color: #545454;
        opacity: 0.8;
    }
}


.user-edit-t {
    min-width: 500px;
}

/* Ensure horizontal scrolling at the bottom of the table */



.p-datatable-scrollable-body {
    overflow: auto;
    position: relative;
}
