
.table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.absent{
    background-color:red;
    height:15px;
    width:150px;
    border:none ;
    color:white;
    border-radius: 6px;
    padding:5px;
}
.present{
    background-color:blue;
    height:15px;
    width:150px;
    border:none ;
    color:white;
    border-radius: 6px;
    padding:5px;

}

.active-tab-trainer {
    cursor: pointer;
    font: normal normal normal 15px/23px Poppins;
    font-weight: 600;
    letter-spacing: 0.09px;
    color: "Poppins"; 
    margin-right: 20px;
    padding-bottom: 4px;
    border-bottom: 3px solid var(--primary-color);
}

.inactive-tab-trainer {
    cursor: pointer;
    font: normal normal normal 14px/21px Poppins;
    background-color: transparent;
    letter-spacing: 0.09px;
    color: grey;
    margin-right: 20px;
}

.continue-btn {
    display: flex;
    flex-direction: row-reverse;
}

