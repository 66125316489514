@font-face {
    font-family: 'Nunito Sans11';
    src: local('Nunito Sans'), url(./NunitoSans-Black.ttf) format('truetype');
}

@font-face {
    font-family: 'Nunito Sans1';
    src: local('Nunito Sans'), url(./NunitoSans-BlackItalic.ttf) format('truetype');
}

@font-face {
    font-family: 'Nunito Sans Bold';
    src: local('Nunito Sans'), url(./NunitoSans-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'Nunito Sans3';
    src: local('Nunito Sans'), url(./NunitoSans-BoldItalic.ttf) format('truetype');
}

@font-face {
    font-family: 'Nunito Sans4';
    src: local('Nunito Sans'), url(./NunitoSans-ExtraBold.ttf) format('truetype');
}

@font-face {
    font-family: 'Nunito Sans5';
    src: local('Nunito Sans'), url(./NunitoSans-ExtraBoldItalic.ttf) format('truetype');
}

@font-face {
    font-family: 'Nunito Sans6';
    src: local('Nunito Sans'), url(./NunitoSans-ExtraLight.ttf) format('truetype');
}

@font-face {
    font-family: 'Nunito Sans7';
    src: local('Nunito Sans'), url(./NunitoSans-ExtraLightItalic.ttf) format('truetype');
}

@font-face {
    font-family: 'Nunito Sans8';
    src: local('Nunito Sans'), url(./NunitoSans-Italic.ttf) format('truetype');
}

@font-face {
    font-family: 'Nunito Sans9';
    src: local('Nunito Sans'), url(./NunitoSans-Light.ttf) format('truetype');
}

@font-face {
    font-family: 'Nunito Sans10';
    src: local('Nunito Sans'), url(./NunitoSans-LightItalic.ttf) format('truetype');
}

@font-face {
    font-family: 'Nunito Sans';
    src: local('Nunito Sans'), url(./NunitoSans-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Nunito Sans12';
    src: local('Nunito Sans'), url(./NunitoSans-SemiBold.ttf) format('truetype');
}

@font-face {
    font-family: 'Nunito Sans13';
    src: local('Nunito Sans'), url(./NunitoSans-SemiBoldItalic.ttf) format('truetype');
}

/* @font-face {
    font-family: 'DS-Digital';
    src: local('DS-Digital'), url(./DS-DIGIB.TTF) format('truetype');
} */

/* @font-face {
    font-family: 'Segoe UI';
    src: local('Segoe UI'), url(./segoeui.ttf) format('truetype');
}

@font-face {
    font-family: 'Oregano Italic';
    src: local('Oregano Italic'), url(./Oregano-Italic.ttf) format('truetype');
}
*/
/* @font-face {
    font-family: 'Oregano Regular';
    src: local('Oregano Regular'), url(./Oregano-Regular.ttf) format('truetype');
} */

@font-face {
    font-family: 'Open Sans';
    src: local('Open Sans'), url(./OpenSans-Regular.ttf) format('truetype');
} 

/* @font-face {
    font-family: 'Muli';
    src: local('Muli'), url(./Muli.ttf) format('truetype');
}

@font-face {
    font-family: 'Nexa-Bold';
    src: local('Nexa-Bold'), url(./NexaBold.otf) format('truetype');
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppins'), url(./Poppins-Regular.ttf) format('truetype');
} */

/* 
@font-face {
    font-family: 'ArcaMajora3-Heavy';
    src: local('ArcaMajora3-Heavy'), url(./ArcaMajora3-Heavy.otf) format('truetype');
}


@font-face {
    font-family: 'ArcaMajora3-Bold';
    src: local('ArcaMajora3-Bold'), url(./ArcaMajora3-Bold.otf) format('truetype');
} */


@font-face {
    font-family: 'Roboto';
    src: local('Roboto'), url(./Roboto-Regular.ttf) format('truetype');
}

/* 
@font-face {
    font-family: 'Source Sans Pro';
    src: local('Source Sans Pro'), url(./SourceSansPro-Regular.ttf) format('truetype');
}
 */



@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat'), url(./Montserrat-Bold.ttf) format('truetype');
}



@font-face {
    font-family: 'Poppins';
    src: local('Montserrat'), url(./Poppins-Regular.ttf) format('truetype');
}


@font-face {
    font-family: 'AbhayaLibre';
    src: local('AbhayaLibre'), url(./AbhayaLibre-Bold.ttf) format('truetype');
}


@font-face {
    font-family: 'AlexBrush';
    src: local('AlexBrush'), url(./AlexBrush-Regular.ttf) format('truetype');
}
